<template>
<div class='card'>
    <TabView v-model:activeIndex='activeTabIndex'>
        <TabPanel :header="'//Role'">
            <h5 class='font-bold mb-0'>{{ roleName }}</h5>
            <Divider type='dashed' class='mt-0'></Divider>
            <div class='p-fluid grid mb-0'>
                <div class="field col-12 md:col-4 mt-2">
                  <span class="p-float-label">
                    <InputText id="roleName" type="text" :disabled='true' v-model='roleName'/>
                    <label for="roleName" class='font-light'>//Code</label>
                  </span>
                </div>
                <div class='col-12 md:col-12'></div>
                <div class="field col-12 hr-line-bottom">
                  <span class="p-float-label">
                    <Textarea id='commentInput' v-model='servletValue' :autoResize='true' style='width: 100%'></Textarea>
                    <label for="commentInput" class='font-bold'>//Servlet</label>
                  </span>
                </div>
                <div class='col-12 ml-0'>
                    <h5 class='font-bold hr-line-dashed-bottom'>{{ t['471'] }}</h5>
                    <div class='hr-line-dashed-bottom'>
                        <div class='ml-2 mb-2'>
                            <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-danger'/>
                            <span class='font-light' style='font-size: 12px'>{{ '   ' + t['463'] }}</span>
                            <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-warning ml-2'/>
                            <span class='font-light' style='font-size: 12px'>{{ '   ' + t['464'] }}</span>
                            <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-success ml-2'/>
                            <span class='font-light' style='font-size: 12px'>{{ '   ' + t['465'] }}</span>
                        </div>
                    </div>
                    <div class='dashboard'>
                        <div class='task-list'>
                            <ul>
                                <li v-for='role in roleTranslationsList' :key='role.roleCode' style='border-bottom: none; padding: 0px 0px;'>
                                    <div class="p-inputgroup md:col-6">
                                        <span class="p-inputgroup-addon" style='list-style-type: none;'>{{role.langCode}}</span>
                                        <InputText placeholder="Website" v-model='role.name' style='width: 50%'/>
                                        <Button v-bind:class="[role.status === 1 ? 'p-button-danger' : role.status === 2 ? 'p-button-warning' : 'p-button-success']" @click='role.status = ((role.status)%3)+1'></Button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Divider type='dashed' class='mb-0'></Divider>
            <div class='mt-2'>
                <Button icon='pi pi-save' :label="t['87']" class='mr-2' @click='updateTableValues'></Button>
                <Button icon='pi pi-times' :label="t['86']" class='p-button-outlined' @click="backToRoles"></Button>
            </div>
        </TabPanel>
        <TabPanel :header="'//Permissions'">
<!--            <h5 class='font-bold mb-0'>//Permissions</h5>-->
<!--            <Divider type='dashed' class='mt-0'></Divider>-->
            <div v-for='permissionGroup in simplifiedPermissionsList' :key='permissionGroup.pgCode' class='mb-4'>
                <h5 class='font-bold mb-0'>{{ t[permissionGroup.txtId] }}</h5>
                <Divider class='mt-0'></Divider>
                <PickList v-model='permissionGroup.joinedPermissions'
                          listStyle='height: 342px' dataKey='code' v-model:selection='selection'>
                    <template #sourceHeader>
                        //All Available Permissions
                    </template>
                    <template #targetHeader>
                        //Role Permissions
                    </template>
                    <template #item='slotProps'>
                        <div class='product-item'>
                            <div class="product-list-detail">{{ t[slotProps.item.txtId] }}
<!--                                <br>-->
<!--                                <i class="pi pi-tag product-category-icon"></i>-->
<!--                                <span class="product-category">{{ slotProps.item.code }}</span>-->
                            </div>
                        </div>
                    </template>
                </PickList>
            </div>
            <Divider type='dashed' class='mb-0'></Divider>
            <div class='mt-2'>
                <Button icon='pi pi-save' :label="t['87']" class='mr-2' @click='updatePermissions'></Button>
                <Button icon='pi pi-times' :label="t['86']" class='p-button-outlined' @click="backToRoles"></Button>
            </div>
        </TabPanel>
        <TabPanel :header="'//Users'">
            <h5 class='font-bold mb-0'>{{ t['436'] + ' - ' + roleName }}</h5>
            <Divider type='dashed' class='mt-0'></Divider>
            <DataTable :value='usersList' :loading='loading' :paginator='true' :rows='10' :rowsPerPageOptions='[10, 20, 50]' class='p-datatable-sm'
                       :filters='filters'
                       :resizableColumns='true' columnResizeMode='fit'
                       v-model:selection='userSelection' selectionMode='single'>
                <div class='text-right mb-1'>
                    <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText v-model="filters['global'].value" :placeholder="t['82']"/>
                    </span>
                    <Button class='ml-2' :label='"Add User"' icon='pi pi-plus' @click='displayAddEmployeeDialog = !displayAddEmployeeDialog' />
                </div>
                <Column field='uid' :header="t['430']" headerStyle='width: 20%'></Column>
                <Column :header="t['116']" headerStyle='width: 30%'>
                    <template #body='slotProps'>
                        <div>
                            <span>{{ slotProps.data.uname }}</span>
                        </div>
                    </template>
                </Column>
                <Column header="" headerStyle='width: 50%'>
                    <template #body='slotProps'>
                        <div class='p-align-center' @click='unassignedUser=slotProps.data; confirmUnassign()'>
                            <i class='pi pi-user-minus'></i>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </TabPanel>
    </TabView>
    <Dialog header="//Add Employee" v-model:visible="displayAddEmployeeDialog"
            :breakpoints="{'960px': '75vw'}" :style="{width: '25vw'}" :modal='true'>
        <Divider class='mt-0'></Divider>
        <Dropdown v-model="selectedUser" :options="unassignedUsersList" optionLabel="value"
                  :filter="true" placeholder="//Select user to assign to role..." :showClear="true">
            <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                    <div>{{slotProps.value.value}}</div>
                </div>
                <span v-else>
                    {{slotProps.placeholder}}
                </span>
            </template>
            <template #option="slotProps">
                <div class="country-item">
                    <div>{{slotProps.option.value}}</div>
                </div>
            </template>
        </Dropdown>
        <Divider class='mb-0'></Divider>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" @click="displayAddEmployeeDialog = !displayAddEmployeeDialog; selectedUser=null;" class="p-button-outlined"/>
            <Button label="Save" icon="pi pi-check" @click="assignToUser()" class="p-button-outlined" autofocus/>
        </template>
    </Dialog>
</div>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import { computed } from 'vue';
import { FilterMatchMode } from 'primevue/api';

export default {
    name: 'EditRole',
    setup() {
        const store = useStore()
        const primeVue = usePrimeVue()

        return {
            token: computed(() => store.state.bearerToken),
            t: primeVue.config.locale,
        }
    },
    data() {
        return {
            roleName: this.$route.params.roleName,
            activeTabIndex: 0,
            loading: true,
            servletValue: null,
            roleTranslationsList: null,
            simplifiedPermissionsList: [],
            selection: [],
            usersList: null,
            userSelection: null,
            filters: null,
            displayAddEmployeeDialog: false,
            selectedUser: null,
            unassignedUsersList: [],
            unassignedUser: null,
        }
    },
    watch: {
        '$route'(to) {
            this.roleName = to.params.roleName
        },
        activeTabIndex: function() {
            sessionStorage.setItem('editRoleTabIndex', this.activeTabIndex)
        },
    },
    created() {
        this.initFilter()
    },
    mounted() {
        this.getRoleData()
        this.getUnassignedUsers()
    },
    methods: {
        getRoleData() {
            this.loading = true
            axios
                .get( '/administration/role/' + this.roleName + '/get',  {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then( res => {
                    if(res.status === 200 && res.data.rmsList[0].retCode === 0){
                        this.servletValue = res.data.retObj.servlet
                        this.roleTranslationsList = res.data.retObj.roleTranslations
                        let allAvailablePermissionsList = res.data.retObj.selectablePermissions
                        let grantedPermissionsList = res.data.retObj.selectedPermissions
                        let allPermissionsList = [ allAvailablePermissionsList, grantedPermissionsList ]

                        let proxyPermissions = []
                        for(let i = 0; i < allPermissionsList.length; i++) {
                            for(let j = 0; j < allPermissionsList[i].length; j++) {
                                proxyPermissions.push(allPermissionsList[i][j])
                            }
                        }

                        for(let k = 0; k < proxyPermissions.length; k = k + 2) {
                            let permissionGroupCode = proxyPermissions[k].pgCode
                            let permissionGroupTranslation = proxyPermissions[k].txtId
                            let selectablePermissions = proxyPermissions[k].permissions
                            let selectedPermissions = proxyPermissions[k+1].permissions
                            let joinedPermissions = [ selectablePermissions, selectedPermissions ]
                            let permissionsItem = {
                                'pgCode' : permissionGroupCode,
                                'txtId' : permissionGroupTranslation,
                                'joinedPermissions' : joinedPermissions
                            }

                            this.simplifiedPermissionsList.push(permissionsItem)
                        }

                        this.usersList = res.data.retObj.users
                        this.loading = false
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                    }
                })
                .catch(error => {
                    console.log("There was an error loading role data!")
                    console.log(error)
                })
        },
        backToRoles() {
            // this.$router.replace('/roles')
        },
        updateTableValues() {
            console.log(this.roleName)
            this.$router.replace('/roles')
        },
        initFilter() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        updatePermissions() {
            let sentPermissions = []

            for(let i = 0; i < this.simplifiedPermissionsList.length; i++) {
                for (let j = 0; j < this.simplifiedPermissionsList[i].joinedPermissions[1].length; j++) {
                    sentPermissions.push(this.simplifiedPermissionsList[i].joinedPermissions[1][j].code)
                }
            }

            axios
                .post('administration/role/'+ this.roleName + '/updatePermission', {
                    'permissions' : sentPermissions
                }, {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then(res => {
                    if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
                        setTimeout(() => {
                            this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
                        }, 500)
                        this.backToRoles()
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                    }
                })
                .catch(error  => {
                    console.log(error);
                    // this.backToRoles()
                })
        },
        assignToUser() {
            axios
                .post('administration/role/'+ this.roleName + '/assignTo/' + this.selectedUser.key, {
                }, {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then(res => {
                    if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
                        setTimeout(() => {
                            this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
                        }, 500)
                        this.getUnassignedUsers()
                        this.selectedUser = null
                        this.displayAddEmployeeDialog = false
                        this.loading = true
                        this.getRoleData()
                        this.backToRoles()
                    }else {
                        this.displayAddEmployeeDialog = false
                        this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                    }
                })
                .catch(error  => {
                    console.log(error);
                    // this.backToRoles()
                })

            this.selectedUser = null
        },
        getUnassignedUsers() {
            axios
                .get( 'app/webObjects/dropdown/USER_WITHOUT_x_ROLE/VALUE/false/get?key=' + this.roleName,  {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then( res => {
                    if(res.status === 200 && res.data.rmsList[0].retCode === 0){
                        this.unassignedUsersList = res.data.retObj.dropDownList
                        console.log(this.unassignedUsersList)
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                    }
                })
                .catch(error => {
                    console.log("There was an error loading role data!")
                    console.log(error)
                })
        },
        unassingUser() {
            axios
                .post('administration/role/'+ this.roleName + '/remove/' + this.unassignedUser.uid, {
                }, {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then(res => {
                    if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
                        setTimeout(() => {
                            this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
                        }, 500)
                        this.loading = true
                        this.getRoleData()
                        this.backToRoles()
                        this.unassignedUser = null
                    }else {
                        this.displayAddEmployeeDialog = false
                        this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                    }
                })
                .catch(error  => {
                    console.log(error);
                    // this.backToRoles()
                })
        },
        confirmUnassign() {
            this.$confirm.require({
                message: '//Are you sure you want to unassign ' + this.unassignedUser.uname,
                header: '//Unassign user',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.unassingUser()
                },
                reject: () => {
                    this.unassignedUser = null
                }
            });
        },
    }
};
</script>

<style scoped lang='scss'>
.product-item {
    display: flex;
    align-items: center;
    padding: .5rem;
    width: 100%;

    img {
        width: 75px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 1rem;
    }

    .product-list-detail {
        flex: 1 1 0;
    }

    .product-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
    }

    .product-category {
        vertical-align: middle;
        line-height: 1;
    }
}

@media screen and (max-width: 576px) {
    .product-item {
        flex-wrap: wrap;

        .image-container {
            width: 100%;
            text-align: center;
        }

        img {
            margin: 0 0 1rem 0;
            width: 100px;
        }
    }
}
</style>